import React, { useState} from 'react';
import './App.css';
import Search from './Search';
import Donate from './Donate';


function App() {
  const [showDonate, setShowDonate] = useState(false);

  const handleDonateClick = (event) => {
    event.preventDefault();
    if (showDonate) {
      setShowDonate(false);
    } else {
    setShowDonate(true);
  }
};

  return (
    <div className='main'>
      <div>
        <nav className='nav'>
          <div className='main_title'>
            <div>Tuesdays with Stories</div>
            {/* <div>
              {showDonate ? <button type="button" className='back' onClick={handleDonateClick}>Back to Search</button> : <button type="button" className='donate' onClick={handleDonateClick}>Support a Tuesgay</button>}
            </div> */}
          </div>
        </nav>
        </div>
        {showDonate ? <Donate /> : <Search />}
    </div>
  );
}

export default App;
