import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { BiSearchAlt } from 'react-icons/bi';


const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchCount, setSearchCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [prevSearchTerm, setPrevSearchTerm] = useState('');
    const [more, setMore] = useState('');
  
    const divRef = useRef(null);
  
    const page = useRef(1);
  
    const API_ENDPOINT = 'https://fastapi-tws-production.up.railway.app/tws/';
  
    const handleChange = (event) => {
      event.preventDefault();
      setSearchTerm(event.target.value);
      page.current = 1;
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setSearchCount(0)
      setIsLoading(true);
      setScroll(true);
      page.current = 1;
      const cleanedValue = searchTerm.replace(/[^a-zA-Z ']/g, "");
      if (searchTerm !== prevSearchTerm) {
        page.current = 1;
        setPrevSearchTerm(searchTerm);
      }
      axios.get(`${API_ENDPOINT}${cleanedValue}/${page.current}`)
        .then((response) => {
          setSearchResults(response.data.results);
          setSearchCount(response.data.count);
          setIsLoading(false);
          setScroll(false);
          if (response.data.count > 23){
            setMore(`More ${searchTerm}`);
            setHasMoreData(true);
          }else{
            setMore(`No more ${searchTerm}`);
            setHasMoreData(false);
          }
  
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    };
  
    const handleMore = (event) => {
      event.preventDefault();
      if (divRef.current && hasMoreData && searchCount > 23) {
        const cleanedValue = searchTerm.replace(/[^a-zA-Z ']/g, "");
        if (searchTerm === prevSearchTerm) {
          page.current = page.current + 1;
        }
        axios.get(`${API_ENDPOINT}${cleanedValue}/${page.current}`)
          .then((response) => {
            if (response.data.results.length) {
              setSearchResults([...searchResults, ...response.data.results]);
              if (`${searchCount}`/23 > `${page.current}`){
                setMore(`More ${prevSearchTerm}`);
              }else{
                setMore(`No more ${prevSearchTerm}`);
                setHasMoreData(false);
              }
            } else {
              setHasMoreData(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    const openInNewTab = (event, url) => {
      event.preventDefault()
      window.open(url, "_blank", "noreferrer");
    };
  
    const scrollToTop = () => {
      if (divRef.current) {
        divRef.current.scroll({
          top: 0,
          behavior: "smooth"
        });
      }
    };

  
    useEffect(() => {
      scrollToTop();
    }, [scroll, searchTerm]);

    useEffect(() => {
      // Prompt confirmation when reload page is triggered
      window.onbeforeunload = () => { return "" };
          
      // Unmount the window.onbeforeunload event
      return () => { window.onbeforeunload = null };
  }, []);
  
  
    return (
      <div className='main_body'>
        <div className='first'>
          <form onSubmit={handleSubmit} className="form container">
            <input type="text" className="text_input" placeholder="Give it a goog" value={searchTerm} onChange={handleChange} autoFocus />
            <button className="button" type="submit" disabled={!searchTerm || searchTerm === prevSearchTerm} aria-label="search"><BiSearchAlt /></button>
          </form>
          {searchCount ? <div className="container count">
            Results: {searchCount}
          </div> : <div></div>}
        </div>
        {isLoading ? (
          <div className='container'>
            <div className="loader"></div></div>
        ) : (
          <div className={searchCount ? "results top_border" : "results"} ref={divRef}>
            {searchResults.sort((a, b) => a.id > b.id ? -1 : 1).map((result) => (
              <div key={result.id}
                className="result">
                <div className="container title">{result.title}</div>
                <div className="container ref">{result.ref}</div>
                <div className="container"><p className='alink' onClick={(event) => openInNewTab(event, result.link)}>
                  {result.link}</p></div>
                {/* <div className="container">Time: {result.starttime}</div> */}
              </div>
            ))}
            {searchCount ? <div className='container more' onClick={handleMore}>
          {more}
        </div> : <div className='white'></div>}
          </div>
        )}
        
      </div>
    );
  }
  

export default Search;