import React from "react";

const Donate = () => {
  return (
    <div className="donate-container">
      <p className="donate-text"><strong>Find me on</strong><a className="paypallink" href="https://www.paypal.com/paypalme/twspod" target="_blank" rel="noreferrer"> <img className='paypal' src="./images/paypal.png" alt="Donate"/>paypal.me/twspod</a></p>
      <p  className="donate-text"><strong>or on</strong> <a href="https://wise.com/invite/u/mustafaa2739" target="_blank" rel="noreferrer"> <img className='wise' src="./images/wise.png" alt="Donate"/></a> <strong>through email:</strong></p>
      <ul className='details'>
        <li><strong>lukaalkafiri@gmail.com</strong></li>
      </ul>
      <p className="note">*If <strong>Wise</strong> transfer is not in USD, please send me an email and I will provide you with the relevant account information.</p> 
      <div className="queef1">
      <p className="queef">Queef!</p>
      </div>
    </div>
  );
};

export default Donate;